/**********Sticky header ************ */
document.addEventListener("DOMContentLoaded", onDOMReady);
function onDOMReady() {
    let menuHeader = document.querySelector('header');
    let mainContainer = document.querySelector('main');
    if(Boolean(menuHeader, mainContainer)) {
        window.addEventListener('scroll', onWindowScroll);
        function onWindowScroll() {
            if(window.document.scrollingElement.scrollTop > 120){
                menuHeader.classList.add("sticky");
                mainContainer.classList.add("main__margin-top");
            }
            else {
                menuHeader.classList.remove("sticky")
                mainContainer.classList.remove("main__margin-top");
            }
        }
    }
}
/********** Button back  ************ */
const pushHistori = () => {
    if(document.querySelector('.push-history')) {
        document.querySelector('.push-history').addEventListener("click", (e) => {
            e.preventDefault();
            window.history.back();
        })
    }
}
pushHistori();
/********** Not click in lang menu current item ************ */
if(document.querySelector('.current-language-menu-item')) {
    document.querySelector('.current-language-menu-item > a').addEventListener('click', (e) => {
        e.preventDefault();
    });
}
/********** show/hide category in News page and Gallery page ************ */
if(document.querySelector('.category-list-btn') && document.querySelectorAll('.category-filter').length > 0) {
    const openBtn = document.querySelector('.category-list-btn');

    const handleButtonsShow = () => {
        document.querySelectorAll('.category-filter').forEach((item) => {
            item.classList.toggle('show-cat');
        });
        openBtn.classList.toggle('show-cat');
    }

    openBtn.addEventListener('click', handleButtonsShow);
}
/*********** modal ************/
const modal = () => {
    let eventBtn = ".modal-form";
    let modalId = "modal-contact";
    if(!document.querySelectorAll(eventBtn).length || !document.getElementById(modalId)) {
        return;
    }
    let getModal = document.getElementById(modalId);
    let getBtn = document.querySelectorAll(eventBtn);
    let closeBtn = getModal.querySelector(".close-modal");
    let modalContain = getModal.querySelector(".container");

    const openModal = () => {
        getModal.classList.add("show");
        document.querySelector("body").classList.add("stop");
    };
    const closeModal = () => {
        getModal.classList.remove("show");
        document.querySelector("body").classList.remove("stop");
    };
    const showNoti = () => {
        modalContain.classList.add("show");
        setTimeout(hideNoti, 1500);
    };
    const hideNoti = () => {
        closeModal();
        setTimeout(modalContain.classList.remove("show"), 1000);
    };

    getBtn.forEach((item) => item.addEventListener("click", openModal));
    getModal.addEventListener("click", (e) => {
        e.preventDefault();
        modalContain.contains(e.target) ? "" : closeModal();
    });

    closeBtn.addEventListener("click", closeModal);
    document.addEventListener("keydown", (e) => {
        e.key === "Escape" ? closeModal() : "";
    });

    document.addEventListener(
        "wpcf7mailsent",
        (event) => {
            if (event.detail.contactFormId == id) {
                showNoti();
            }
        },
        false
    );
};
modal();
/********** back-to-top-btn ************ */
    function backToTop(){
        if(document.querySelectorAll('#back-to-top-btn').length > 0){
            document.querySelector('#back-to-top-btn').addEventListener('click', function(){
                document.querySelector('html,body').animate({
                        scrollTop: 0
                    },
                    400
                );
            });

            window.addEventListener('scroll', function(){
                if(window.document.scrollingElement.scrollTop>= document.querySelector('#page-body').scrollTop) {
                    document.querySelector('#back-to-top-btn').classList.add('show-this');
                }
                else {
                    document.querySelector('#back-to-top-btn').classList.remove('show-this');
                }
            })
        }
    }
    backToTop();
/* **** Burger **** */
const burgerHandler = () => {
    if(!document.querySelector('.burger-menu')) {
        return;
    }
    const burgerBtn = document.querySelector('.burger-menu');
    const menu = document.querySelector('.main-menu');
    const body = document.querySelector('body');
    burgerBtn.addEventListener('click', handleMenu);
    function handleMenu() {
        burgerBtn.classList.toggle('show');
        menu.classList.toggle('show');
        body.classList.toggle('show');
    }
}
burgerHandler();
/* **** Fax **** */
const faxHandlerClick = () => {
    if(!document.querySelector('.fax-link')) {
        return;
    }
    const faxLink = document.querySelector('.fax-link');
    faxLink.addEventListener('click', function(event) {
        event.preventDefault();
        try {
            window.open(this.href);
        } catch (error) {
            alert('You do not have fax software installed!');
            console.log(error);
        }
    });
}
faxHandlerClick();
/* **** Menu drop down **** */
const menuDropDown = () => {
    if(!(document.querySelectorAll('.menu-item-has-children').length > 0)) {
        return;
    }
    const menus = document.querySelectorAll('.menu-item-has-children');
    menus.forEach((item) => {
        item.addEventListener('click', () => {
            item.classList.toggle('show');
        })
    })
}
menuDropDown();
