const accordion = () => {
    if(!(document.querySelectorAll('.accordion').length > 0)) {
        return;
    }
    const accordionList = document.querySelectorAll('.accordion');
    accordionList.forEach((accordion) => {
        const items = accordion.querySelectorAll('.accordion-item');
        items.forEach((item) => {
            const title = item.querySelector('.accordion-item__title');
            const description = item.querySelector('.accordion-item__description');
            title.addEventListener('click', () => {
                if(item.classList.contains('show')) {
                    item.classList.remove('show');
                    setTimeout(() => { description.style.display = 'none'; },300);
                } else {
                    description.style.display = 'block';
                    item.classList.add('show');
                }
            });
        });
    });
}
accordion();
